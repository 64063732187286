import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'

import './styles/main.scss'
import App from './components/App'

const tagManagerArgs = {
  gtmId: 'GTM-TP2M4B9'
}

TagManager.initialize(tagManagerArgs)

axios.defaults.baseURL = process.env.REACT_APP_API_URL

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
