import React, {Component, lazy} from 'react'
import PropTypes from 'prop-types'
import {Switch, Route, Redirect, withRouter} from 'react-router-dom'
import axios from 'axios'
import {forceCheck} from 'react-lazyload'
import withWidth from '@material-ui/core/withWidth'

import withNotification from '../_decorators/withNotification'
const Home = lazy(() => import('../Home'))
const Blog = lazy(() => import('../Blog'))
const Article = lazy(() => import('../Article'))
const Subscription = lazy(() => import('../Subscription'))

const isSubscriptionPage = pathname => pathname.startsWith('/discount') || pathname.startsWith('/subscription')

class AppContent extends Component {
  state = {
    articles: null,
    tabs: null,
    activeTab: 0
  }
  tabsCount = null

  static propTypes = {
    location: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,

    onErrorNotificationOpen: PropTypes.func.isRequired,
    onFetchToggle: PropTypes.func.isRequired
  }

  componentDidMount() {
    const {location, onErrorNotificationOpen} = this.props
    if (isSubscriptionPage(location.pathname)) return
    axios
      .get('api/blogArticle/list')
      .then(({data}) => {
        let tabs = [
          'all',
          ...new Set(
            data.data.map(article => {
              const category = article.tags.find(tag => tag.asCategory)
              return category && category.title
            })
          )
        ]
        tabs = tabs.filter(tab => tab)
        this.tabsCount = tabs.length
        this.setState({
          tabs,
          articles: data.data.filter(article => article.tags.length)
        })
      })
      .catch(error => onErrorNotificationOpen(error))
  }

  handleTabAdd = tab => () => {
    const tabs = [...this.state.tabs]
    tabs[this.tabsCount] = `#${tab}`
    this.setState({tabs, activeTab: this.tabsCount})
    setTimeout(() => document.getElementById('tabs').scrollIntoView(true), 0)
  }

  handleActiveTabChange = (event, value) => {
    this.setState({activeTab: value}, forceCheck)
  }

  handleActiveTabReset = () => this.setState({activeTab: 0})

  render() {
    const {width, isFetch, onErrorNotificationOpen, onFetchToggle} = this.props
    const {articles, tabs, activeTab} = this.state
    const onActiveTabReset = activeTab !== 0 ? this.handleActiveTabReset : null
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={props => <Home {...props} width={width} articles={articles} onActiveTabReset={onActiveTabReset} />}
        />
        <Route
          exact
          path="/blog"
          render={props => (
            <Blog
              {...props}
              width={width}
              articles={articles}
              tabs={tabs}
              activeTab={activeTab}
              onTabAdd={this.handleTabAdd}
              onActiveTabChange={this.handleActiveTabChange}
            />
          )}
        />
        <Route
          exact
          path="/blog/:slug"
          render={props => (
            <Article
              {...props}
              width={width}
              articles={articles}
              onTabAdd={this.handleTabAdd}
              onActiveTabReset={onActiveTabReset}
            />
          )}
        />
        <Route
          exact
          path="/discount"
          render={props => (
            <Subscription
              width={width}
              isFetch={isFetch}
              onErrorNotificationOpen={onErrorNotificationOpen}
              onFetchToggle={onFetchToggle}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/subscription/manage"
          render={props => (
            <Subscription
              isManage
              width={width}
              isFetch={isFetch}
              onErrorNotificationOpen={onErrorNotificationOpen}
              onFetchToggle={onFetchToggle}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/subscription/manage/cancel/paypal"
          render={props => (
            <Subscription
              isManage
              isPayPalCancel
              width={width}
              isFetch={isFetch}
              onErrorNotificationOpen={onErrorNotificationOpen}
              onFetchToggle={onFetchToggle}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/subscription/manage/cancel/paypal/open"
          component={() => {
            global.window && (global.window.location.href = 'https://www.paypal.com');
            return null;
          }}
        />
        <Redirect to="/" />
      </Switch>
    )
  }
}

export default withRouter(withWidth()(withNotification(AppContent)))
