import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/ErrorRounded'
import CloseIcon from '@material-ui/icons/CloseRounded'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

const SERVER_ERROR = 'Server connection error'

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
}

const styles = {
  error: {
    borderRadius: 30,
    backgroundColor: '#e67673',
    flexWrap: 'nowrap'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8
  },
  message: {
    fontFamily: '"Boston", "Arial", sans-serif',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center'
  }
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  error: PropTypes.object,

  onClose: PropTypes.func.isRequired
}

function Notification({classes, isOpen, error, onClose}) {
  const action = [
    <IconButton key="close" color="inherit" className={classes.close} onClick={onClose}>
      <CloseIcon className={classes.icon} />
    </IconButton>
  ]
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={anchorOrigin}
      ContentProps={{
        className: classes.error
      }}
      message={
        <span className={classes.message}>
          <ErrorIcon className={classes.iconVariant} />
          {error && error.response
            ? error.response.data.errors
              ? Object.values(error.response.data.errors).join(', ')
              : error.response.data.message || SERVER_ERROR
            : SERVER_ERROR}
        </span>
      }
      action={action}
      onClose={onClose}
    />
  )
}

export default withStyles(styles)(Notification)
