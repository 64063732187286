import React, {Suspense} from 'react'
import {Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import CssBaseline from '@material-ui/core/CssBaseline'
import MessengerCustomerChat from 'react-messenger-customer-chat'

import Loader from '../_common/Loader'
import ScrollToTop from '../_common/ScrollToTop'
import AppContent from '../AppContent'
//import Cookies from './Cookies'
import ReactPixel from 'react-facebook-pixel'

const history = createBrowserHistory()
window.routerHistory = history

const reactPixelOptions = {
  autoConfig: true, // set pixel's autoConfig
  debug: false // enable logs
}
ReactPixel.init('973763349383813', {}, reactPixelOptions)
ReactPixel.pageView()

export default function App() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <div className="FadeIn">
          <CssBaseline />
          <Suspense fallback={<Loader isApp />}>
            <AppContent />
          </Suspense>
          <MessengerCustomerChat
            pageId="237621873756327"
            appId="260474001377189"
            themeColor="#F3b4a5"
            greetingDialogDisplay="hide"
          />
          {/* <Cookies /> */}
        </div>
      </ScrollToTop>
    </Router>
  )
}
