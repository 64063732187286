import {createPortal} from 'react-dom'
import React from 'react'
import PropTypes from 'prop-types'
import {CSSTransition} from 'react-transition-group'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import './style.scss'

const root = document.getElementById('root')

const classNames = {
  enter: 'LoaderEnter',
  enterActive: 'LoaderEnterActive',
  exit: 'LoaderExit',
  exitActive: 'LoaderExitActive'
}

const StyledCircularProgress = withStyles({
  root: {
    color: '#ffbdb5',
    margin: 'auto'
  }
})(CircularProgress)

Loader.propTypes = {
  isFetch: PropTypes.bool,
  isWrapper: PropTypes.bool,
  isApp: PropTypes.bool,
  isBlock: PropTypes.bool
}

export default function Loader({isFetch, isApp, isWrapper, isBlock}) {
  const loader = <StyledCircularProgress size={50} />
  if (isWrapper)
    return createPortal(
      <CSSTransition in={isFetch} classNames={classNames} timeout={150} unmountOnExit>
        <div className="Loader Loader_wrapper">{loader}</div>
      </CSSTransition>,
      root
    )
  if (isApp)
    return (
      <div className="Loader">
        <div>{loader}</div>
      </div>
    )
  if (isBlock) {
    return (
      <CSSTransition in={isFetch} classNames={classNames} timeout={150} unmountOnExit>
        <div className="Loader Loader_block">{loader}</div>
      </CSSTransition>
    )
  }
  return loader
}
