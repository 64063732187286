import {Component} from 'react'
import {withRouter} from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const {location} = this.props
    if (
      !location.hash &&
      !location.pathname.startsWith('/subscription') &&
      !location.pathname.startsWith('/discount') &&
      location !== prevProps.location
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
